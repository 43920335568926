.authority {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999999;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
.img {
  width: 280px;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(1,20,58,0.9000);
  padding-top: 24px;
}

.tip {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(1,20,58,0.3000);
  padding-top: 8px;
}
.btn {
  margin-top: 24px;
}
